import axios from "axios";
import { globalStore } from "@/stores/global";




var prod_url = "https://webhook.streamgamestv.com";
var dev_url = "http://localhost:3000"


const axiosClient = axios.create({
    baseURL: prod_url,
});

axiosClient.interceptors.request.use((config) => {
    const store = globalStore();
    config.headers.Authorization = `Bearer ${store.auth0Token}`
    config.headers.Accept = "application/json"
    return config
})

export async function axiosGet(url: string, params?: any): Promise<any> {
    let response: any = {};
    try {
        response = await axiosClient.get(url, {
            params: params
        });
    } catch (error: any) {
        console.log(error.response);
        return false;
    }

    if (response.status == 204) {
        return true
    } else {
        return response.data;
    }
}

export async function axiosPost(url: string, body?: any): Promise<any> {
    let response: any = {};

    try {
        response = await axiosClient.post(url, body);
    } catch (error: any) {
        console.log(error.response);
    }

    if (response.status == 204) {
        return true
    } else {
        return response.data;
    }
}

export async function axiosPut(url: string, body: any): Promise<any> {
    let response: any = {};

    try {
        response = await axiosClient.put(url, body);
    } catch (error: any) {
        console.log(error.response);
    }

    if (response.status == 204) {
        return true
    } else {
        return response.data;
    }
}

export async function axiosDelete(url: string, body: any): Promise<any> {
    let response: any = {};

    try {
        response = await axiosClient.delete(url, body);
    } catch (error: any) {
        console.log(error.response);
    }

    if (response.status == 204) {
        return true
    } else {
        return response.data;
    }
}