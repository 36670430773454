
import { defineComponent } from "vue";
import { globalStore } from "@/stores/global";

export default defineComponent({
data() {
    return {
      store: null as any,
    }
  },
  mounted() {
    this.store = globalStore();
  },
  methods: {
    openClientPortal() {
      //@ts-ignore
      window.open("https://payments.pabbly.com/portal/app/en/streamgamestvportal/subscription/1/all", '_blank').focus();
    }
  }
});
