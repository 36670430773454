<template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-fluid">
      <div class="navbar-brand">
        <img height="40" src="@/assets/logo.png" alt="" />
        <a class="mx-2">StreamGamesTV</a>
      </div>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#"></a>
          </li>
        </ul>
      </div>
      <div class="socials">
        <a href="https://twitter.com/StreamGamesTV" target="blank"
          ><i class="fa-brands fa-twitter"></i></a
        ><a target="blank" href="https://www.youtube.com/channel/UC77UmGDaaE8DVgxA3EIw4cA"
          ><i class="fa-brands fa-youtube"></i></a
        ><a href="https://discord.com/invite/6Mmfpvf" target="blank"
          ><i class="fa-brands fa-discord"></i></a
        ><a href="https://www.instagram.com/streamgamestv/" target="blank"
          ><i class="fa-brands fa-instagram"></i
        ></a>
      </div>
    </div>
  </nav>
  <div class="sidebar">
    <div
      @click="this.$router.push('/')"
      class="sidebar-item"
      :class="active == '/' ? 'sidebar-active' : ''"
    >
      <i class="fa-solid fa-house"></i>
    </div>
    <div
      @click="this.$router.push('/settings')"
      class="sidebar-item"
      :class="active == '/settings' ? 'sidebar-active' : ''"
    >
      <i class="fa-solid fa-gear"></i>
    </div>
    <div @click="logout" class="sidebar-item-last">
      <i class="fa-solid fa-arrow-right-from-bracket"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "",
    };
  },
  mounted() {
    this.$router.isReady().then(() => {
      this.active = this.$route.fullPath;
      this.$router.afterEach(() => {
        this.active = this.$route.fullPath;
      });
    });
  },
  methods: {
    logout() {
      this.$auth0.logout({ logoutParams: { returnTo: "https://streamgamestv.com/" } });
    },
  },
};
</script>

<style scoped>
.socials {
  display: flex;
}
.socials > a {
  font-size: 1.3rem;
  padding-right: 0.7rem;
}
.navbar {
  background: #0e0e10 !important;
}
a {
  text-decoration: none;
  color: white;
}
.sidebar {
  position: absolute;
  background: #0e0e10 !important;
  height: 100vh;
  width: 5rem;
  text-align: center;
}
.sidebar-active {
  background: #222227;
}
.sidebar-item {
  color: white;
  margin-left: -0.3rem;
  font-size: 1.3rem;
  cursor: pointer;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.sidebar-item-last {
  position: absolute;
  color: white;
  margin-left: 1.75rem;
  bottom: 4.5rem;
  font-size: 1.3rem;
  cursor: pointer;
  padding-bottom: 0.7rem;
}
</style>
