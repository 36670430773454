import { defineStore } from "pinia";
import { Auth0VueClient } from '@auth0/auth0-vue';
import { axiosGet, axiosPost } from "@/util/axios";
import { Product } from "./interfaces/product.interface";

interface State {
    auth0: Auth0VueClient | null;
    products: Product[];
    userProductSkus: string[];
    demos: {expired: boolean; sku: string; timestamp: number}[];
    auth0Token: string;
    isLoading: boolean;
    isFailed: boolean;
}
let retry = 0;
export const globalStore = defineStore("global", {
    state: (): State => {
        return {
            auth0: null,
            products: [],
            userProductSkus: [],
            demos: [],
            auth0Token: "",
            isLoading: true,
            isFailed: false
        }
    },
    actions: {
        async loadStuff() {
            if(this.auth0 && this.auth0.user && this.auth0.user.sub) {
                this.userProductSkus = await axiosGet(`/user/${this.auth0.user.sub}/products`)
                if(!this.userProductSkus && retry === 0) {
                    retry = 1;
                    await axiosPost("/user", {
                        email: this.auth0.user.email,
                        id: this.auth0.user.sub
                    })
                    this.loadStuff();
                    return;
                }

                this.products = await axiosGet(`/product`);
                this.demos = await axiosGet(`/user/${this.auth0.user.sub}/demos`);
                this.isLoading = false;

                if(!this.products || !this.demos) {
                    this.isFailed = true;
                }
            }
        },
        async setAuth0Token() {
            if (this.auth0) {
                try {
                    this.auth0Token = await this.auth0.getAccessTokenSilently()
                } catch (error) {
                    this.auth0.loginWithRedirect();
                }
            } else {
                console.error("auth0 not defined")
            }
        },
        async activateDemo(sku: string) {
            await axiosPost("/user/activateDemo/" + sku);
            await this.loadStuff();
        }
    }
})