import { createApp } from 'vue'
import App from './App.vue'
import { createAuth0 } from '@auth0/auth0-vue';
import router from './router'
import { createPinia } from 'pinia'


//vue
const app = createApp(App);

//auth0
const client = createAuth0({
    domain: "streamgamestv.eu.auth0.com",
    clientId: "Bba0tCN9hyHC0AcHIdP0qLNuT0EUA8WI",
    authorizationParams: {
        audience: "https://firebase.streamgamestv.com",
        redirect_uri: window.location.origin
    }
})

app.use(client);

//pinia
const pinia = createPinia()
app.use(pinia)


//set store values
import { globalStore } from "@/stores/global";
const store = globalStore();
// @ts-ignore
store.auth0 = client

app.use(router).mount('#app')
