
import { defineComponent } from "vue";
import Navbar from "@/components/navbar.vue";
import { globalStore } from "@/stores/global";
import Sidebar from "@/components/Sidebar.vue";

export default defineComponent({
  async mounted() {
    setTimeout(async () => {
      const store = globalStore();
    await store.setAuth0Token()
    store.loadStuff()
    }, 200);
  },
  components: {
    Sidebar,
    Navbar
  },
});
